<template>
    <div>
        <loading-full-screen v-model="isCancellingRequest" loadingText="Cancelling leave request ..."/>
        <card-general>
            <section-header>Reimbursement Requests</section-header>
            <div v-if="!isValidArray(reimbursementRequestList)">
                Ready to submit your first reimbursement request? Use the allowance blocks above to get started.
            </div>
            <div v-else>

                <div class="d-flex justify-content-between mt-1 align-items-center flex-wrap">
                    <div class="flex-grow-1 d-flex align-items-center flex-wrap">
                        <reimbursement-claims-filter
                            filter-name="Reimbursement Allowance" filter-identifier="reimbursement-type-filter" placeholder-text="Select reimbursement allowance ..."
                            :filter-options="filterTypeOptions" @option-selected="optionSelectedHandler" class="me-2"
                            :selected-options-string="filteredTypeListString"
                            options-selected-label="allowances selected"
                        />
                        <reimbursement-claims-filter
                            filter-name="Claim Status" filter-identifier="status-filter" placeholder-text="Select claim status ..."
                            :filter-options="filterStatusOptions" @option-selected="optionSelectedHandler" class="me-2"
                            :selected-options-string="filteredStatusListString"
                            options-selected-label="statuses selected"
                        />
                        <div class="status-tooltip-cont">
                            <info-tooltip
                                :tip-content="reimbursementStatusExplanation" v-if="reimbursementStatusExplanation"
                                class="gradient-green-text-dark d-inline-block pb-5 mb-1"
                            />
                        </div>
                    </div>
                </div>

                <div v-if="apiErrorMessage || successMessage" id="api-message-response-container">
                    <div v-if="apiErrorMessage">
                        <bento-alert class="mt-4" static color="danger" icon-type="error-warning" dismiss v-model="showApiErrorMessage">
                            <span v-html="apiErrorMessage"></span>
                        </bento-alert>
                    </div>
                    <div v-if="successMessage">
                        <bento-alert icon-type="success-tick" class="mt-4" static dismiss v-model="showSuccessMessage">
                            {{ successMessage }}
                        </bento-alert>
                    </div>
                </div>

                <div>
                    <div v-for="request in paginatedItems" :key="request.id" class="d-flex my-4 font-size-13 align-items-center request-row">
                        <div class="d-flex align-items-center status-and-date">
                            <div :class="request.state" class="state-bubble font-weight-500 py-1 text-white text-center me-2 me-sm-3">{{ request.state === 'rejected' ? 'Declined' : toTitleCase(request.state) }}</div>
                            <div class="request-dates me-2 me-sm-3 me-md-4">{{ formatDate(request.date) }}</div>
                        </div>

                        <div class="font-size-12">
                            <div>
                                <span class="fw-500">{{ bentoCurrencyFormat(request.amount, 2, true) }}</span> - {{ findAllowanceNameById(request.allowance) }}
                                <span class="d-none d-md-inline" v-if="request.notes">&#x2022; {{ textTruncaterWords(request.notes, 40) }}</span>
                            </div>
                            <div v-if="request.approver">
                                <span>{{ request.state === 'approved' ? 'Approved by ' : 'Declined by ' }}</span>{{ request.approver?.name }}
                                <span v-if="request.state === 'approved'">on {{ shortDateFormat(request.approval_date) }}</span>
                            </div>
                            <div>
                                <span><span class="text-danger fw-500" v-if="request.error_message">{{ textTruncaterWords(request.error_message, 40) }}</span></span>
                            </div>
                        </div>
                        <div class="cancel-col text-end flex-grow-1 font-weight-500 cursor-pointer" v-if="showCancelButton(request)">
                            <div class="cancel-btn py-1 px-3 d-inline-block" @click="cancelRequestHandler(request)">Cancel</div>
                        </div>
                    </div>

                    <div class="pagination">
                        <a @click="prevPage" :class="{ disabled: currentPage === 1 }">&laquo;</a>
                        <a v-for="page in totalPages" :key="page" @click="goToPage(page)" :class="{ active: currentPage === page }">{{ page }}</a>
                        <a @click="nextPage" :class="{ disabled: currentPage === totalPages }">&raquo;</a>
                    </div>
                </div>
            </div>
        </card-general>
    </div>
</template>

<script setup>
import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
import SectionHeader from "@/components/UI/v2/containers-cards-headers/SectionHeader.vue"
import {computed, ref, watch} from "vue"
import {useStore} from "vuex"
import {toTitleCase, textTruncaterWords} from "@/hooks/general/text-formatters"
import {isValidArray} from "@/hooks/general/type-and-value-checks"
import {formatDate, shortDateFormat} from "@/hooks/general/date-helpers"
import LoadingFullScreen from "@/components/UI/v2/loading/LoadingFullScreen"
import BentoAlert from "@/components/UI/v2/alerts/BentoAlert.vue"
import ReimbursementClaimsFilter
    from "@/components/benefits-leave-reimbursements/reimbursements/components/reimbursement-claims-lists/ReimbursementClaimsFilter.vue"
import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters";
import InfoTooltip from "@/components/UI/v2/misc/InfoTooltip.vue";


const store = useStore()
const props = defineProps({
    isAdHoc: {type: Boolean, default: false}
})

const reimbursementStatusExplanation = computed(() => {
  if (props.isAdHoc) {
    return `<p>When a claim is approved in Bento, it has been sent to accounting for processing. For updates on the payout, please contact your HR or payments administrator.</p>`
  } else {
    return `<p>When a claim is approved in Bento, this means that is has been posted to payroll to be included as a reimbursement on your payslip.</p>`
  }
})

// used in filter options below
const reimbursementAllowanceList = props.isAdHoc ? store.getters.reimbursementAllowanceListAdHocOnly : store.getters.reimbursementAllowanceListExclAdHoc

// user to filter the claims list between ad hoc and normal
const adhocIds = reimbursementAllowanceList.filter(allowance => allowance.method === 'adhoc').map(item => item.id)

// the list of reimbursement requests
const reimbursementClaimList = computed(() => store.getters.reimbursementClaimList)
const reimbursementRequestList = computed(() => {
    if (!isValidArray(reimbursementClaimList.value)) return null
    if (props.isAdHoc) {
        return reimbursementClaimList.value.filter(claim => adhocIds.includes(claim.allowance))
    } else {
        return reimbursementClaimList.value.filter(claim => !adhocIds.includes(claim.allowance))
    }
})
const reimbursementRequestListReversed = computed(() => reimbursementRequestList.value ? reimbursementRequestList.value.slice().reverse() : null)
const reimbursementRequestListRef = ref(reimbursementRequestListReversed.value) // need this because we are mutating this with the filters
watch(reimbursementRequestListReversed, (newVal) => {
    reimbursementRequestListRef.value = newVal
    optionSelectedHandler()
})

// pagination logic (from GPT-4)
const itemsPerPage = 12
const currentPage = ref(1)
const totalPages = computed(() => {
    if (!isValidArray(reimbursementRequestListRef.value)) return 0
    return Math.ceil(reimbursementRequestListRef.value.length / itemsPerPage)
})
const paginatedItems = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    if (!isValidArray(reimbursementRequestListRef.value)) return []
    return reimbursementRequestListRef.value.slice(startIndex, endIndex)
})
const goToPage = (page) => currentPage.value = page
const nextPage = () => currentPage.value < totalPages.value && currentPage.value++
const prevPage = () => currentPage.value > 1 && currentPage.value--


// filter options (static)
const filterTypeOptions = []
for (const balanceObj of reimbursementAllowanceList) {
    const option = {text: balanceObj.name, value: balanceObj.name}
    filterTypeOptions.push(option)
}

const filterStatusOptions = [
    {text: 'Submitted', value: 'submitted'},
    {text: 'Approved', value: 'approved'},
    {text: 'Declined', value: 'rejected'},
    {text: 'Cancelled', value: 'cancelled'},
    {text: 'Error', value: 'error'}
]

// Selected options (dynamic with user select)
const filteredTypeList = ref(null)
const filteredTypeListString = computed(() => isValidArray(filteredTypeList.value) ? filteredTypeList.value.join(',') : null)

const filteredStatusList = ref(null)
const filteredStatusListString = computed(() => isValidArray(filteredStatusList.value) ? filteredStatusList.value.join(',') : null)

// allowance name
const findAllowanceNameById = id => reimbursementAllowanceList.find(allowance => allowance.id === id)?.name

// Update list of requests and selected options
const optionSelectedHandler = (selectedOptionsList, filterIdentifier) => {
    if (filterIdentifier === 'reimbursement-type-filter') {
        filteredTypeList.value = selectedOptionsList.value
    }
    if (filterIdentifier === 'status-filter') {
        filteredStatusList.value = selectedOptionsList.value
    }

    // if none selected for either filter then don't show any requests
    if (!isValidArray(filteredTypeList.value) || !isValidArray(filteredStatusList.value)) {
        reimbursementRequestListRef.value = null
    } else { // else work out the combo of selected options
        reimbursementRequestListRef.value = reimbursementRequestListReversed.value.filter(request => filteredTypeList.value.includes(findAllowanceNameById(request.allowance)) && filteredStatusList.value.includes(request.state))
    }
}

const showCancelButton = (request) => {
    void (request)
    return false

    // Not showing cancel button as 500 server error when trying to cancel own request
    // return (request.state == 'submitted')
}

const showSuccessMessage = ref(true)
const successMessage = ref(null)
const isCancellingRequest = ref(false)
const showApiErrorMessage = ref(true)
const apiErrorMessage = ref(null)

// Not showing cancel button
// 500 server error when trying to cancel own request. Don't think server allows cancelled state to be PATCH'ed
const cancelRequestHandler = async (request) => {
    isCancellingRequest.value = true
    apiErrorMessage.value = null
    successMessage.value = null

    let response
    try {
        response = await store.dispatch('cancelReimbursementClaim', {requestID: request.id, updatedState: "cancelled"})
        await store.dispatch('loadReimbursementClaimList', {forceRefresh: true})
    } catch (error) {
        apiErrorMessage.value = error.message
        isCancellingRequest.value = false
        return
    }

    if (response && response.response.status === 200) {
        let state = response.responseData.state
        if (state === 'error') {
            apiErrorMessage.value = `Error cancelling claim. Please <a class="font-weight-700 text-decoration-underline" href="mailto:hello@mybento.net">contact us</a> if you require assistance.`
        } else {
            successMessage.value = `Reimbursement claim cancelled.`
        }
    } else {
        apiErrorMessage.value = `Error cancelling claim. Please <a class="font-weight-700 text-decoration-underline" href="mailto:hello@mybento.net">contact us</a> if you require assistance.`
    }

    isCancellingRequest.value = false
}
</script>

<style scoped lang="scss">
@import "@/styles/global-scss/variables-and-mixins.scss";

.status-tooltip-cont {
    @media (max-width: 340px) {
        display: none;
    }
}

.status-and-date {
    flex: 0 0 192px;
    @include media-breakpoint-up(sm) {
        flex: 0 0 220px;
    }
}

.state-bubble {
    background-color: black;
    border-radius: 8px;
    flex: 0 0 84px;

    &.approved {
        background-color: #25885E;
    }
    &.cancelled {
        background-color: #B4B4B4;
    }
    &.error {
        background-color: #D5474C;
    }
    &.rejected {
        background-color: #CAA5A5;
    }
}

.cancel-col {
    min-width: 71px;
}
.cancel-btn {
    transition: all .2s ease-in-out;
    &:hover {
        background-color: rgba(0, 0, 0, .1);
        border-radius: 8px;
    }
}

// pagination
.pagination {
    display: flex;
    justify-content: center;
    margin: 1rem;
}

.pagination a {
    padding: 0.5rem;
    margin: 0.25rem;
    border: 1px solid #ccc;
    text-decoration: none;
    color: #333;
    cursor: pointer;
}

.pagination a.active {
    background-color: #25885E;
    border-color: #25885E;
    color: white;
}
</style>