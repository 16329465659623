<template>
    <div id="leave-requests-table">
        <loading-full-screen v-model="isCancellingRequest" loadingText="Cancelling leave request ..."/>
        <card-general>
            <section-header>Leave Requests</section-header>
            <div class="d-flex justify-content-between mt-3 align-items-center flex-wrap" v-if="isValidArray(leaveRequestList)">
                <div class="flex-grow-1 d-flex align-items-center flex-wrap">
                    <leave-requests-filter
                        filter-name="Leave Type" filter-identifier="leave-type-filter" placeholder-text="Select leave type ..."
                        :filter-options="filterTypeOptions" @option-selected="optionSelectedHandler" class="me-2"
                        :selected-options-string="filteredTypeListString"
                        options-selected-label="leave types selected"
                    />
                    <leave-requests-filter
                        filter-name="Leave Status" filter-identifier="status-filter" placeholder-text="Select leave status ..."
                        :filter-options="filterStatusOptions" @option-selected="optionSelectedHandler" class="me-2"
                        :selected-options-string="filteredStatusListString"
                        options-selected-label="statuses selected"
                    />
                </div>
            </div>

            <div v-if="successMessage">
                <bento-alert class="mt-4" static dismiss v-model="showSuccessMessage">
                    {{ successMessage }}
                </bento-alert>
            </div>

            <div v-if="isValidArray(leaveRequestList)">
                <div v-for="request in paginatedItems" :key="request.id" class="d-flex my-4 py-1 font-size-13 align-items-center request-row">
                    <div class="d-flex align-items-center status-and-date">
                        <div :class="request.state" class="state-bubble font-weight-500 text-white text-center me-2 me-sm-3">{{ request.state === 'rejected' ? 'Declined' : toTitleCase(request.state) }}</div>
                        <div class="request-dates me-2">
                            <div>{{ dateRangeFormatter(request.start_date, request.end_date) }}</div>
                            <div class="font-size-11 text-gray-700 pt-1">Days: {{ request.days }}</div>
                        </div>
                    </div>
                    <div class="font-size-12">
                        <div>
                            <span>{{ leaveTypeNameTextOutput(request) }}&nbsp;<span class="d-none d-md-inline" v-if="request.notes">&#x2022;&nbsp;{{ textTruncaterWords(request.notes, 40) }}</span></span>
                        </div>
                        <div v-if="request.approver">
                            <span>{{ request.state === 'approved' ? 'Approved by ' : 'Declined by ' }}</span>{{ request.approver?.name }}
                        </div>
                        <div>
                            <span><span class="text-danger fw-500" v-if="request.error_message">{{ textTruncaterWords(parseErrorMessage(request.error_message), 40) }}</span></span>
                        </div>
                    </div>

                    <div class="cancel-col text-end flex-grow-1 font-weight-500 cursor-pointer" v-if="showCancelButton(request)">
                        <div class="cancel-btn py-1 px-3 d-inline-block" @click="cancelRequestHandler(request)">Cancel</div>
                    </div>
                </div>

                <div class="pagination">
                    <a @click="prevPage" :class="{ disabled: currentPage === 1 }">&laquo;</a>
                    <a v-for="page in totalPages" :key="page" @click="goToPage(page)" :class="{ active: currentPage === page }">{{ page }}</a>
                    <a @click="nextPage" :class="{ disabled: currentPage === totalPages }">&raquo;</a>
                </div>
            </div>
            <div v-else class="mt-2">
                It looks like you haven't requested any leave yet. You can submit your first request using the calendar above.
            </div>
        </card-general>
    </div>
</template>

<script setup>
import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
import SectionHeader from "@/components/UI/v2/containers-cards-headers/SectionHeader.vue"
import {computed, ref, watch} from "vue"
import {useStore} from "vuex"
import {toTitleCase} from "@/hooks/general/text-formatters"
import {dateRangeFormatter} from "@/hooks/general/date-helpers"
import LeaveRequestsFilter
    from "@/components/benefits-leave-reimbursements/leave/components/leave-requests-lists/LeaveRequestsFilter.vue"
import {isValidArray} from "@/hooks/general/type-and-value-checks"
import LoadingFullScreen from "@/components/UI/v2/loading/LoadingFullScreen"
import BentoAlert from "@/components/UI/v2/alerts/BentoAlert.vue"
import {textTruncaterWords} from "@/hooks/general/text-formatters"

const store = useStore()

// the list of leave requests
const leaveRequestList = computed(() => store.getters.leaveRequestList)
const leaveRequestListReversed = computed(() => leaveRequestList.value ? leaveRequestList.value.slice().reverse() : null)
const leaveRequestListRef = ref(leaveRequestListReversed.value) // need this because we are mutating this with the filters
watch(leaveRequestListReversed, (newVal) => {
    leaveRequestListRef.value = newVal
    optionSelectedHandler()
})

// pagination logic (from GPT-4)
const itemsPerPage = 12
const currentPage = ref(1)
const totalPages = computed(() => {
    if (!isValidArray(leaveRequestListRef.value)) return 0
    return Math.ceil(leaveRequestListRef.value.length / itemsPerPage)
})
const paginatedItems = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    if (!isValidArray(leaveRequestListRef.value)) {
        return []
    }
    return leaveRequestListRef.value.slice(startIndex, endIndex)
})
const goToPage = (page) => currentPage.value = page
const nextPage = () => currentPage.value < totalPages.value && currentPage.value++
const prevPage = () => currentPage.value > 1 && currentPage.value--


// filter options (static)
const leaveBalancesSorted = store.getters.leaveBalancesSorted
const filterTypeOptions = []
for (const balanceObj of leaveBalancesSorted) {
    const option = {text: balanceObj.type.description, value: balanceObj.type.description}
    filterTypeOptions.push(option)
}

const filterStatusOptions = [
    {text: 'Submitted', value: 'submitted'},
    {text: 'Approved', value: 'approved'},
    {text: 'Declined', value: 'rejected'},
    {text: 'Cancelled', value: 'cancelled'},
    {text: 'Error', value: 'error'}
]

// Selected options (dynamic with user select)
const filteredTypeList = ref(null)
const filteredTypeListString = computed(() => isValidArray(filteredTypeList.value) ? filteredTypeList.value.join(',') : null)

const filteredStatusList = ref(null)
const filteredStatusListString = computed(() => isValidArray(filteredStatusList.value) ? filteredStatusList.value.join(',') : null)

// Update list of requests and selected options
const optionSelectedHandler = (selectedOptionsList, filterIdentifier) => {
    if (filterIdentifier === 'leave-type-filter') {
        filteredTypeList.value = selectedOptionsList.value
    }
    if (filterIdentifier === 'status-filter') {
        filteredStatusList.value = selectedOptionsList.value
    }

    // if none selected for either filter then don't show any requests
    if (!isValidArray(filteredTypeList.value) || !isValidArray(filteredStatusList.value)) {
        leaveRequestListRef.value = null
    } else { // else work out the combo of selected options
        leaveRequestListRef.value = leaveRequestListReversed.value.filter(request => filteredTypeList.value.includes(request.type.description.toString()) && filteredStatusList.value.includes(request.state)
        )
    }
}

// request cancellation
const showCancelButton = (request) => {
    if (request.state == 'submitted') return true
    if (request.state == 'rejected' || request.state == 'cancelled' || request.state == 'error') return false

    const currentDate = new Date()
    currentDate.setHours(23, 59, 59, 998)
    const leaveStartDate = new Date(request.start_date)
    return leaveStartDate > currentDate
}

const showSuccessMessage = ref(true)
const successMessage = ref(null)
const isCancellingRequest = ref(false)
const cancelRequestHandler = async (request) => {
    isCancellingRequest.value = true
    successMessage.value = null

    try {
        await store.dispatch('updateLeaveRequestState', {requestID: request.id, updatedState: "cancelled"})
    } catch (error) {
        store.commit('setShowApiErrorModal', true)
        store.commit('setApiErrorModalTitle', 'Error cancelling your leave request')
        store.commit('setApiErrorMessage', error.message)
        isCancellingRequest.value = false
        return
    }

    successMessage.value = `Leave request cancelled.`
    isCancellingRequest.value = false
}

const cleanName = (name) => {
    return name.replace(/Leave/g, '').trim()
}
const leaveTypeNameTextOutput = (request) => {
    return cleanName(request?.type?.description || request?.type?.name)
}

const parseErrorMessage = (errorMessage) => {
    try {
        const parsed = JSON.parse(errorMessage)
        return parsed.Message || errorMessage
    } catch (e) {
        return errorMessage
    }
};

</script>

<style scoped lang="scss">
.request-row {
    line-height: 1.35;
}

.status-and-date {
    flex: 0 0 226px;
}

.state-bubble {
    padding: 5px 1px;
    background-color: black;
    border-radius: 8px;
    flex: 0 0 84px;

    &.approved {
        background-color: #25885E;
    }
    &.cancelled {
        background-color: #B4B4B4;
    }
    &.error {
        background-color: #D5474C;
    }
    &.rejected {
        background-color: #CAA5A5;
    }
}

.request-dates {
    flex: 0 0 124px;
}

.cancel-col {
    min-width: 71px;
}
.cancel-btn {
    transition: all .2s ease-in-out;
    &:hover {
        background-color: rgba(0, 0, 0, .1);
        border-radius: 8px;
    }
}

// pagination
.pagination {
    display: flex;
    justify-content: center;
    margin: 1rem;
}

.pagination a {
    padding: 0.5rem;
    margin: 0.25rem;
    border: 1px solid #ccc;
    text-decoration: none;
    color: #333;
    cursor: pointer;
}

.pagination a.active {
    background-color: #25885E;
    border-color: #25885E;
    color: white;
}
</style>